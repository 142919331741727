import React from 'react';
import './Shippingpolicy.css';

const ShippingPolicy = () => {
  return (
    <div className="policy-container">
      <h1>Shipping Policy</h1>
      <p>
        Vinculo is committed to delivering your order with good quality packaging within the given time frame. To ensure that your order reaches you in good condition, in the shortest span of time, we ship through reputed courier agencies only.
      </p>
      
      <h2>Shipping Costs</h2>
      <p>
        Shipping charges will be applicable as per the weight of the product and the destination. These charges will be clearly displayed at checkout.
      </p>
      
      <h2>Order Processing Time</h2>
      <p>
        All orders are processed within 1-2 business days. Orders are not shipped or delivered on holidays.
      </p>
      
      <h2>Shipping Carriers and Methods</h2>
      <p>
        We use a variety of shipping carriers, including but not limited to DHL, FedEx, and local postal services. The shipping method will be chosen based on your location and the fastest available service.
      </p>
      
      <h2>Delivery Speed</h2>
      <p>
        Delivery of all orders will be duly done to the address as mentioned by you at the time of placing the order. Typically, deliveries take between 4 to 8 working days.
      </p>
      
      <h2>Handling Delays or Service Interruptions</h2>
      <p>
        In the event of a delay or service interruption, we will notify you as soon as possible with the new expected delivery date. We strive to minimize any inconvenience and keep you informed throughout the process.
      </p>
      
      <h2>Late or Damaged Items</h2>
      <p>
        If your item arrives late or damaged, please contact our Customer Service team immediately. We will work with you to either replace the item or provide a refund, based on the situation.
      </p>
      
      <h2>Returns and Cancellations</h2>
      <p>
        If you wish to return or cancel your order, please refer to our Cancellation & Refund Policy for detailed instructions. We aim to provide a smooth and hassle-free process for our customers.
      </p>
      
      <h2>FAQs</h2>
      <ul>
        <li><strong>What happens if I am not available to receive my order?</strong> - The shipping carrier will attempt delivery up to three times. If undelivered, the item will be returned to our warehouse.</li>
        <li><strong>Can I change my shipping address after placing an order?</strong> - Please contact our Customer Service team as soon as possible. We can update the address if the order has not yet been shipped.</li>
        <li><strong>How can I track my order?</strong> - Once your order is shipped, you will receive an email with the tracking information. You can use this to track your order online.</li>
      </ul>
      
      <h2>Contact Us</h2>
      <p>
        If you have any questions about our Shipping Policy, please contact us at:
      </p>
      <ul>
        <li><strong>Merchant Legal Entity Name:</strong> SRI SHANMUGAVEL MILLS PRIVATE LIMITED</li>
        <li><strong>Address:</strong> UNIT I - SF NO 2612/2, VEDASANDUR ROAD, KIRIYAMPATTI, AGARAM, DINDIGUL, TAMIL NADU, 624709, DINDIGUL, Tamil Nadu, PIN: 624709</li>
        <li><strong>Telephone No:</strong> 9150086952</li>
        <li><strong>E-Mail ID:</strong> <a href="mailto:ssm@vinculoshop.com">ssm@vinculoshop.com</a></li>
      </ul>
    </div>
  );
};

export default ShippingPolicy;

import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-container">
      <h1>About Vinculo</h1>
      <p>
        Welcome to Vinculo, where fashion meets comfort for the little ones in your life. We are a passionate team dedicated to creating high-quality, stylish, and comfortable t-shirts for boys, girls, and infants. Our journey began with a simple idea: to offer parents and kids a unique blend of trendy designs and cozy wear that fits perfectly into everyday life.
      </p>

      <h2>Our Mission</h2>
      <p>
        At Vinculo, we believe that fashion should be fun, functional, and free-spirited. Our mission is to provide t-shirts that kids love to wear and parents can trust for quality and durability. Every piece is crafted with care, using the finest fabrics and attention to detail, ensuring that your little ones not only look good but feel great.
      </p>

      <h2>Why Choose Us?</h2>
      <ul>
        <li><strong>Exclusive Designs:</strong> Our t-shirts feature original designs that you won’t find anywhere else. We collaborate with talented designers to bring fresh, vibrant, and playful prints to life.</li>
        <li><strong>Comfort First:</strong> We understand the importance of comfort for children. That’s why we use soft, breathable materials that are gentle on delicate skin.</li>
        <li><strong>Sustainability:</strong> Vinculo is committed to responsible fashion. We use eco-friendly processes and materials to ensure that our products are not only good for your kids but also for the planet.</li>
        <li><strong>Online Exclusive:</strong> Vinculo operates exclusively online, making it easy for you to shop from the comfort of your home. With a user-friendly interface, secure payment options, and reliable shipping, we make the shopping experience seamless.</li>
      </ul>

      <p>
        Thank you for choosing Vinculo. We’re excited to be a part of your child’s wardrobe!
      </p>
    </div>
  );
}

export default About;

import React from 'react';
import './CancellationAndRefundPolicy.css';

const CancellationAndRefundPolicy = () => {
  return (
    <div className="policy-container">
      <h1>Cancellation & Refund Policy</h1>
      <p className="last-updated">Last updated on 14-08-2024 10:34:25</p>
      <p>
      SRI SHANMUGAVEL MILLS PRIVATE LIMITED is committed to ensuring customer satisfaction and has implemented a customer-friendly cancellation policy. Under this policy:
      </p>
      <ul>
        <li>
       <b>Cancellation Requests:</b> 

Cancellations will be considered only if the request is made within 24 hours of placing the order. However, if the order has already been processed or dispatched, cancellation requests may not be entertained.
        </li>
        <li>
        <b>Non-Eligible Items for Cancellation:</b>

SRI SHANMUGAVEL MILLS PRIVATE LIMITED does not accept cancellation requests for customized or altered clothing items unless there is a defect in the product.

        </li>
        <li>
       <b> Refunds for Defective or Damaged Items:</b>

If you receive a damaged or defective clothing item, please report it to our Customer Service team within 6 days of receipt. The merchant will verify the defect, and once confirmed, you will be eligible for a refund or replacement.
If the clothing item received does not match the product displayed on the site or does not meet your expectations, this must be reported to our Customer Service within 6 days of receipt. The team will review your complaint and take appropriate action.
        </li>
        <li>
        <b>Products with Manufacturer Warranties:</b>

For clothing items with manufacturer warranties, please contact the manufacturer directly for any warranty-related issues.
        </li>

        <li>
       <b> Refund Processing Time:</b>

Any refunds approved by SRI SHANMUGAVEL MILLS PRIVATE LIMITED will be processed within 6 business days.
        </li>
        <li>
       <b> Refund condition:</b>

To be eligible for a exchange, your item must in the same condition that you received it. It must also be in the original packaging 

        </li>
      </ul>
    </div>
  );
};

export default CancellationAndRefundPolicy;

import React from 'react';
import './PrivacyPolicy.css'; // Optional: if you have separate CSS for styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      

      <h2>1. Introduction</h2>
      <p>
        Welcome to Vinculo! We value your privacy and are committed to protecting your personal information. This policy outlines how we collect, use, and protect your data when you use our website [vinculo.com].
      </p>

      <h2>2. Information We Collect</h2>
      <ul>
        <li><strong>Personal Details:</strong> Name, email, address, and payment information when you create an account or make a purchase.</li>
        <li><strong>Order Details:</strong> Information about your purchases.</li>
        <li><strong>Technical Data:</strong> IP address, browser type, and device information collected via cookies.</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <ul>
        <li><strong>Order Processing:</strong> To fulfill orders and manage payments.</li>
        <li><strong>Customer Support:</strong> To assist with inquiries and improve our service.</li>
        <li><strong>Marketing:</strong> To send updates and promotions if you opt-in.</li>
        <li><strong>Website Improvement:</strong> To enhance your user experience.</li>
      </ul>

      <h2>4. Sharing Your Information</h2>
      <p>
        We do not sell your data. We may share it with:
        <ul>
          <li><strong>Service Providers:</strong> For payment processing and delivery.</li>
          <li><strong>Legal Requirements:</strong> To comply with laws or protect our rights.</li>
        </ul>
      </p>

      <h2>5. Cookies</h2>
      <p>
        We use cookies to enhance your experience. You can manage cookie settings in your browser.
      </p>

      <h2>6. Security</h2>
      <p>
        We use standard security measures to protect your data. However, no system is entirely secure.
      </p>

      <h2>7. Your Rights</h2>
      <p>
        You can request access, correction, or deletion of your personal information. Contact us at [thevinculoshop@gmaill.com] for assistance.
      </p>

      <h2>8. Changes</h2>
      <p>
        We may update this policy and will post changes here. Please review it periodically.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        For questions or concerns, contact Vinculo:
        <ul>
          <li><strong>Email:</strong> ssm@vinculoshop.com</li>
          <li><strong>Address:</strong> VEDASANDUR ROAD, KIRIYAMPATTI, AGARAM, DINDIGUL, TAMIL NADU, PIN: 624709</li>
          <li><strong>Phone:</strong> 9150086952</li>
        </ul>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      
      <div className="contact-details">
        <p><strong>Merchant Legal Entity Name:</strong> SRI SHANMUGAVEL MILLS PRIVATE LIMITED</p>
        <p><strong>Address:</strong> UNIT I - SF NO 2612/2, VEDASANDUR ROAD, KIRIYAMPATTI, AGARAM, DINDIGUL, TAMIL NADU, 624709, DINDIGUL, Tamil Nadu, PIN: 624709</p>
        <p><strong>Telephone No:</strong> 9150080300</p>
        <p><strong>E-Mail ID:</strong> <a href="mailto:ssm@vinculoshop.com">ssm@vinculoshop.com</a></p>
      </div>
    </div>
  );
};

export default ContactUs;